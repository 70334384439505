import script from "./SvDocumentCards.vue?vue&type=script&setup=true&lang=ts"
export * from "./SvDocumentCards.vue?vue&type=script&setup=true&lang=ts"

import "./SvDocumentCards.vue?vue&type=style&index=0&id=0c406984&lang=css"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/.pnpm/@quasar+app-webpack@3.11.3__19fadae530b491e33818dbf1a8400ef1/node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QSpinnerDots,QCardSection,QSeparator,QIcon});
